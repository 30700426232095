<template>
    <div class="page-content">
        <page-breadcrumb title="Offers" class="mb-2" />
        <form-generator :schema="filter_schema" :model="filter" style="width: 100%" />
		<div class="d-flex justify-content-start align-items-center">
			<data-create-popup title="Create Group"
				:schema="create_schema" :default-data="defaultObject"
				:create-data-fn="createItem" @create="$refs.group_list.getList()"
			/>
		</div>
        <data-table-ssr id="group_list" ref="group_list"
            :columns="fields" :get-list-fn="getList"
            :to-edit-fn="toEditPage"
        />
    </div>
</template>
<script>
const create_schema = [
  {
    cols: 12,
    fields: [
        { label: 'Layout', field: 'layout', input_type: 'select', options: [
          {text:"Layout 1", value: 1},
          {text:"Layout 2", value: 2},
          // {text:"Layout 3", value: 3},
          // {text:"Layout 4", value: 4},
          // {text:"Layout 5", value: 5},
          // {text:"Layout 6", value: 6},
          // {text:"Layout 7", value: 7}
        ],
          validate: { required: true }},
        { label: 'Landing page key', field: 'key', validate: { required: true } },
    ]
  }
];

const fields = [
    { label: 'Layout', field: 'layout', input_type: 'select', options: [
      {text:"Layout 1", value: 1},
      // {text:"Layout 2", value: 2},
      // {text:"Layout 3", value: 3},
      // {text:"Layout 4", value: 4},
      // {text:"Layout 5", value: 5},
      // {text:"Layout 6", value: 6},
      // {text:"Layout 7", value: 7}
    ], validate: { required: true }},
    { label: 'Landing page key', field: 'key', validate: { required: true } },
    // { label: 'Store product ID', field: 'store_product_id', value_handler: (model) => {
    //   let product_ids = ""
    //   model.products.forEach((item) => {product_ids += `${item.store_product_id}, `})
    //   return product_ids.replace(/,\s*$/, "");
    // } },
];
import defaultObject from '../defaultObject.js'
import service from '../service'
export default {
    data(){
    return{
      create_schema,
      fields,
      total: 0,
      defaultObject: JSON.parse(JSON.stringify(defaultObject)),
      filter_schema: [{
          cols: 3, fields: [{label: "Key", field: 'key_type', input_type: 'text'}]
        }
      ],
      filter: {},
    }
  },
  watch: {
    filter: {
      handler() {
        if (this.filter.key_type){
          this.filter.key = {$regex: this.filter.key_type, "$options": 'i'}
          delete this.filter.key_type
        }
        this.$refs.group_list.getList();
      },
      deep: true,
    },
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create({ ...data });
      this.$router.push({ name: 'landing-page-detail', params: { id: new_item._id } });
      return new_item;
    },
    async getList({ limit, page }) {
      let response_data = await service.getList({
        query: JSON.stringify(this.filter),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    toEditPage({ _id }) {
      let routeData = this.$router.resolve({ name: 'landing-page-detail', params: { id: _id } });
      window.open(routeData.href, '_blank');
    },
  }
}
</script>